<template>

        <div class="row vertical-align-custom">
<div class="col-xl-8">
    <div class="card card-body text-center mt-3">
        <h5 class="my-3">{{$t('access.t')}}</h5>
        <div class="justify-content-center">
            <h4 class="mb-4">{{$t('access.os')}}</h4>
            <b-tabs pills  content-class="p-3 text-white">
                <b-tab>
                    <template v-slot:title >
                        <span class="d-sm-inline-block">Windows</span>
                    </template>

                    <div class="tab-content text-left">
                        <div class="tab-pane fade active show" id="pills-windows" role="tabpanel">
                            <ol>
                                <li>{{$t('access.win.s1')}}</li>
                                <li class="mt-2">
                                  <a target="_blank" href="https://openvpn.net/client-connect-vpn-for-windows/">{{$t('access.download')}}</a>
                                  {{$t('access.win.s2')}}
                                </li>
                                <li class="mt-2">{{$t('access.win.s3')}}
                                </li>
                                <li class="mt-2">{{$t('access.win.s4')}}
                                </li>
                                <li class="mt-2">{{$t('access.win.s5_1')}} <b>{{$t('access.win.s5_2')}}</b> <br>
                                  <img class="mt-2 mb-3" src="../../assets/images/access/1.png">
                                </li>
                                <li class="mt-2">{{$t('access.win.s6')}}</li>
                                <li class="mt-2">{{$t('access.win.s7')}}
                                  <br><img class="mt-2 mb-3" src="../../assets/images/access/2.png"></li>
                            </ol>
                        </div>
                    </div>
                </b-tab>
                <b-tab active>
                    <template v-slot:title>
                        <span class="d-sm-inline-block">Linux</span>
                    </template>
                    <div class="tab-content text-left">
                      <ol>
                        <li>{{$t('access.linux.s1')}}</li>
                        <li class="mt-2">{{$t('access.linux.s2')}}  <code class="dark font-size-14">sudo apt install openvpn</code></li>
                        <li class="mt-2">{{$t('access.linux.s3')}}  <code class="dark font-size-14">sudo openvpn /path/to/file.ovpn</code></li>
                      </ol>
                    </div>
                </b-tab>
                <b-tab>
                    <template v-slot:title>
                        <span class="d-sm-inline-block">MacOS</span>
                    </template>
                    <div class="tab-content text-left">
                        <ol>
                            <li>{{$t('access.mac.s1')}}</li>
                            <li class="mt-2"><a href="https://openvpn.net/client-connect-vpn-for-mac-os">{{$t('access.download')}}</a> {{$t('access.mac.s2')}}</li>
                            <li class="mt-2">{{$t('access.mac.s3')}}<br><img width="250" class="mt-2 mb-3" src="../../assets/images/access/3.png"></li>
                            <li class="mt-2">{{$t('access.mac.s4')}}</li>
                            <li class="mt-2">{{$t('access.mac.s5_1')}} <code class="dark font-size-14">{{$t('access.mac.s5_2')}}</code> {{$t('access.mac.s5_3')}} <code class="dark font-size-14">{{$t('access.mac.s5_4')}}</code>.<br><img class="mt-2 mb-3" src="../../assets/images/access/4.png"></li>
                            <li class="mt-2">{{$t('access.mac.s6')}}</li>
                            <li class="mt-2">{{$t('access.mac.s7')}}<br><img class="mt-2 mb-3" src="../../assets/images/access/5.png"></li>
                        </ol>
                    </div>
                </b-tab>
            </b-tabs>
        </div>

    </div>
    <div class="card mt-3">
        <div class="card-body">
            <h2>{{$t('access.faq.t')}}</h2>
            <div role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" v-b-toggle.part1 style="cursor: pointer">
                        <h6 class="m-0 text-dark">{{$t('access.faq.tab1.t')}} <i class="fas fa-chevron-down float-right"></i>
                        </h6>
                    </b-card-header>
                    <b-collapse id="part1" class="mx-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-card-text>
                              <ul class="font-size-14 mb-0">
                                <li>{{$t('access.faq.tab1.l1')}}</li>
                                <li >{{$t('access.faq.tab1.l2')}}</li>
                              </ul>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" v-b-toggle.part2 style="cursor: pointer">
                        <h6 class="m-0 text-dark">{{$t('access.faq.tab2.t')}} <i class="fas fa-chevron-down float-right"></i>
                        </h6>
                    </b-card-header>
                    <b-collapse id="part2" class="mx-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-card-text>
                                <ul class="font-size-14 mb-0">
                                    <li>{{$t('access.faq.tab2.l1')}}</li>
                                    <li>{{$t('access.faq.tab2.l2')}}</li>
                                    <li>{{$t('access.faq.tab2.l3')}}</li>
                                    <li>{{$t('access.faq.tab2.l4')}}</li>
                                    <li>{{$t('access.faq.tab2.l5')}}</li>
                                    <li>{{$t('access.faq.tab2.l6')}}</li>
                                    <li>{{$t('access.faq.tab2.l7_1')}} <a href="#" target="_blank">{{$t('access.faq.tab2.l7_2')}}</a> {{$t('access.faq.tab2.l7_3')}} </li>

                                </ul>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" v-b-toggle.part3 style="cursor: pointer">
                        <h6 class="m-0 text-dark">{{$t('access.faq.tab3.t')}} <i class="fas fa-chevron-down float-right"></i>
                        </h6>
                    </b-card-header>
                    <b-collapse id="part3" class="mx-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-card-text>
                              <ul class="font-size-14 mb-0">
                                <li>{{$t('access.faq.tab3.l1')}}</li>
                              </ul>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" v-b-toggle.part5 style="cursor: pointer">
                        <h6 class="m-0 text-dark">{{$t('access.faq.tab4.t')}} <i class="fas fa-chevron-down float-right"></i>
                        </h6>
                    </b-card-header>
                    <b-collapse id="part5" class="mx-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-card-text>
                              <ul class="font-size-14 mb-0">
                                <li>{{$t('access.faq.tab4.l1')}}</li>
                              </ul>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

        </div>
    </div>
</div>
            <div class="col-xl-4 mt-3 border" style="border-radius: 12px;height: fit-content">
                <h4 class="pt-4 text-center">{{$t('access.ip')}}</h4>
                <h2 class="py-2 text-center" v-if="ip">{{ip}}</h2>
                <h2 class="py-2 text-center" v-else>0.0.0.0</h2>

                <b-tabs class="mt-3 " >
                    <b-tab :title="$t('access.machines')" active>
                        <div class="p-4">
                            <div class="input-group  mb-3">
                                <div class="input-group-prepend">
                                    <label class="input-group-text">{{$t('access.vpn')}}</label>
                                </div>
                                <b-form-select class="custom-select" v-model="my_vpn_id" >
                                    <b-form-select-option  :value="vpn_server.id" v-for="vpn_server in vpn_servers" :key="vpn_server.id" >{{vpn_server.name }} {{ vpn_server.is_down?"(Down)": "(up)"}}</b-form-select-option>
                                </b-form-select>
                            </div>
                            <p class="text-lgray size-14 mb-2">{{$t('access.config_p')}}</p>
<div class="row mx-0">
    <button @click="downloadVPNConfig" type="button" class="btn btn-sm mb-2 btn-success">
        <i class="fas fa-download mr-2"></i> {{$t('access.config_download')}}
    </button>
    <button @click="regenVPNConfig" type="button" class="btn btn-sm mb-2  ml-auto btn-info ">
        <i class="fas fa-sync-alt mr-2"></i>{{$t('access.config_reg')}}
    </button>
</div>



                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

</template>

<script>
    import helper from "@/assets/helpers/helper";

    export default {
        name: "Access",
        data() {
            return {

                vpn_servers:[],
                isLoadedVPN:false,
                my_vpn_id:0,
                ip:"0.0.0.0",

            }
            },
         created() {
            this.getMyIP();
            this.getServersVPN()
        },

        methods:{
            changeVPN(vpn_id){
                    this.$http
                    .post('me/vpn',{vpn_server_id:this.my_vpn_id})
                    .then( ( )=> {
                        console.log(vpn_id);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })

            },
            getMyIP(){
                    this.$http
                    .get('me/ip',{id:this.my_vpn_id})
                    .then(response => {
                        this.ip = response.data.ip;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            async downloadVPNConfig(){
                await helper.downloadWithAxios('me/certifs',{id:this.my_vpn_id});

    },
            async regenVPNConfig(){
                  await helper.downloadWithAxios('me/certifs/regenerate',{id:this.my_vpn_id});
            },
            async getServersVPN(){

                this.$http
                    .get('vpn_servers')
                    .then(response =>
                    {
                        this.vpn_servers = response.data;
                        for(let i=0;i<this.vpn_servers.length;i++)
                        {
                            if(this.vpn_servers[i].is_my_vpn)
                            {
                                this.my_vpn_id = this.vpn_servers[i].id

                            }


                        }
                        this.$watch('my_vpn_id', function () {
                                    console.log("ss")
                                    this.changeVPN(this.my_vpn_id);
                            })
                        })

                    .catch(function (error) {
                        console.log(error);
                    })

            }
        }
    }
</script>

<style scoped>
    /deep/ .nav-pills{
    margin-left: 30%;
    }
    /deep/ .nav-pills .nav-link{
        padding: 12px 40px;
        border-radius: 0;
      border: 1px solid #32394e;
    }

    /deep/ .nav-pills .nav-link span{
        color: white;
        font-size: 14px;
        font-weight: bold;
    }
</style>